import listImg from '@images/features/feature-preview-list.png'
import roadmapImg from '@images/features/feature-preview-roadmap.png'
import releasesImg from '@images/features/feature-preview-releases.png'
import widgetImg from '@images/features/feature-preview-widget.png'
import privateImg from '@images/features/feature-preview-private.png'
import darkmodeImg from '@images/features/feature-preview-boards-darkmode.png'

export const features = [
  {
    id: 'feature1',
    short: 'ideas board',
    header:
      'Capture and organize feedback, ideas,\u00A0feature\u00A0requests\u00A0and\u00A0more',
    subheader: '',
    text: `Ideolo allows you to put your idea or feedback in one place on beautiful
    boards. Anyone can see it and participate in the discussion, so
    gather more feedback and ideas with ideolo.`,
    image: listImg,
    showOnHome: true,
  },
  {
    id: 'feature2',
    short: 'Voting system',
    header: 'Collect votes and focus on\u00A0most\u00A0usefull\u00A0features',
    subheader: '',
    text: `Ideolo is a web application to help startups and eterprenours
    gather ideas, feature requests, feedback, claims. And with
    voting, you can make sure your company's most popular ideas get
    in front of the right audience.`,
    image: listImg,
    showOnHome: true,
  },
  {
    id: 'feature3',
    short: 'Roadmap',
    header: 'Plan work and show your progress with\u00A0Roadmaps',
    subheader: '',
    text: `Roadmaps and releases provide a high-level overview of strategic goals and feature releases over time. These tools help teams stay focused, motivated, and responsive to customer needs.`,
    image: roadmapImg,
    showOnHome: true,
  },
  {
    id: 'feature4',
    short: 'Releases',
    header: 'Communicate with your customers using\u00A0Releases',
    subheader: '',
    text: `Roadmaps and releases provide a high-level overview of strategic goals and feature releases over time. These tools help teams stay focused, motivated, and responsive to customer needs.`,
    image: releasesImg,
    showOnHome: true,
  },
  {
    id: 'feature5',
    short: 'Widget',
    header: 'Ideolo board on your website',
    subheader: '',
    text: `All you have to do is just paste few lines of HTML code. It's super simple and can increase numbers of ideas or feedback you collect.`,
    image: widgetImg,
    showOnHome: true,
  },
  {
    id: 'feature6',
    short: 'Private Boards',
    header: 'Private and Public Boards',
    subheader: '',
    text: `Private feedback boards are particularly useful for gathering feedback on new products or features before they are released to the public, as they allow teams to gather feedback and iterate quickly without risking negative feedback or reviews from the public.`,
    image: privateImg,
    showOnHome: false,
  },
  {
    id: 'feature7',
    short: 'Theme and dark mode',
    header: 'Brand your board and\u00A0increase\u00A0usability',
    subheader: '',
    text: `You can edit background and butons colors to match your brand colors or to distinguish diferent boards. Ideolo automatically checks user's system preferences to determine whether to enable or disable dark mode.`,
    image: darkmodeImg,
    showOnHome: false,
  },
  // {
  //   id: 'feature8',
  //   short: 'Mail notifications',
  //   header: '',
  //   subheader: '',
  //   text: ``,
  //   image: darkmodeImg,
  //   showOnHome: false,
  // },
  // {
  //   id: 'feature9',
  //   short: 'Integrations',
  //   header: '',
  //   subheader: '',
  //   text: ``,
  //   image: darkmodeImg,
  //   showOnHome: false,
  // },
]
