import styled, { css } from 'styled-components'

export const Wrapper = styled.article`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`

export const Tweets = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.6s 9s ease;
  ${({ active }) =>
    active
      ? css`
          filter: blur(20px);
          transform: scale(1.2);
          opacity: 0;
          pointer-events: none;
        `
      : css`
          filter: blur(0);
          transform: scale(1);
          opacity: 1;
        `};

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: transform 3.6s ease, opacity 0.6s ease;

    ${({ active }) =>
      active
        ? css`
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `
        : css`
            transform: translate3d(0, 100px, 0);
            opacity: 0;
          `};
  }

  > img:nth-child(1) {
    transition-delay: 0.6s;
  }

  > img:nth-child(2) {
    top: 15%;
    transition-delay: 2.1s;
  }

  > img:nth-child(3) {
    transition-delay: 3.6s;
  }

  > img:nth-child(4) {
    transition-delay: 5.1s;
  }

  > img:nth-child(5) {
    transition-delay: 6.6s;
  }

  ${({ theme }) => theme.media.m} {
    > img {
      width: auto;
    }

    > img:nth-child(1) {
      top: 20%;
      left: 35%;
    }

    > img:nth-child(2) {
      top: 35%;
      left: 60%;
    }

    > img:nth-child(3) {
      top: 15%;
      left: 5%;
    }

    > img:nth-child(4) {
      top: 58%;
      left: 56%;
    }

    > img:nth-child(5) {
      top: 48%;
      left: 11%;
    }
  }
`

export const Preview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  transition: all 0.8s 10s ease;

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
          transform: translate3d(0, 0, 0);
        `
      : css`
          opacity: 0;
          transform: translate3d(0, 20%, 0);
        `}

  > img {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
`
