import styled from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1000;
  overflow: hidden;
  position: relative;
  background: var(--white);
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 8rem;
`

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0 100px;

  > div {
    margin: 0 auto 60px;
    max-width: 200px;

    svg {
      margin: 20px auto;
      width: auto;
      height: 100px;

      path.black {
        fill: var(--black-text);
      }
    }
  }

  ${({ theme }) => theme.media.m} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.media.l} {
    grid-template-columns: repeat(4, 1fr);

    > div {
      margin: 0 auto;
    }
  }
`
