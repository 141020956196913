import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { init } from 'ityped'

const TypedText = ({ text }) => {
  useEffect(() => {
    const myElement = document.querySelector('#typed-text')
    init(myElement, {
      backDelay: 2500,
      loop: true,
      showCursor: true,
      strings: text,
    })
  }, [])

  return <strong id="typed-text" />
}

TypedText.propTypes = {
  text: PropTypes.arrayOf(PropTypes.string),
}

export default TypedText
