import React, { useState, useLayoutEffect } from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'
import HeroSection from '@organisms/HeroSection'
import FeaturesSection from '@organisms/FeaturesSection'
import Steps from '@organisms/Steps'
import SignupCTA from '@organisms/SignupCTA'
import Testimonials from '@organisms/Testimonials'
import FooterCTA from '@organisms/FooterCTA'

import BlogCard from '@molecules/BlogCard'
import BlogGrid from '@molecules/BlogGrid'
import SectionWhite from '@molecules/SectionWhite'
import Container from '@atoms/Container'
import TextBox from '@atoms/TextBox'

const IndexPage = ({ data, transitionStatus }) => {
  const [startPage, setStartPage] = useState(false)
  const pageData = data.pageData
  const blogPostsData = data.blogPosts.nodes
  let params

  useLayoutEffect(() => {
    setTimeout(() => setStartPage(true), 500)

    if (location) {
      params = new URLSearchParams(location.search)
      localStorage.setItem('ref', params.get('ref'))
    }
  }, [setStartPage])

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <HeroSection active={startPage} />
      <FeaturesSection />
      <SignupCTA
        bgColor="gray"
        header={`Gather input from both customers\u00A0and\u00A0teammates`}
        text={
          <p>
            Ensure no valuable ideas are overlooked. Consolidate, evaluate, and
            categorize feedback and feature suggestions using a specialized
            customer feedback platform.
          </p>
        }
      />
      <Steps />
      <Testimonials />
      <SectionWhite>
        <Container>
          <TextBox title="Browse our latest blog posts" center>
            <h2>
              For inspiration and knowledge
              to&nbsp;improve&nbsp;your&nbsp;product
            </h2>
            <p>
              Explore our electrifying blog, crafted to revolutionize how you
              gather feature requests, breakthrough ideas, and user feedback.
              Experience collaborative innovation in action and witness its
              transformative power.
            </p>
          </TextBox>
          <BlogGrid>
            {blogPostsData.map(blogPost => (
              <BlogCard data={blogPost} />
            ))}
          </BlogGrid>
        </Container>
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query HomePageQuery {
    pageData: datoCmsHomePage {
      seoMetaTags {
        tags
      }
    }
    blogPosts: allDatoCmsBlogPost(
      sort: { order: DESC, fields: meta___createdAt }
      limit: 3
    ) {
      nodes {
        id
        slug
        cover {
          alt
          url
        }
        category {
          name
          slug
        }
        title
        shortcut
        lead
        meta {
          createdAt
        }
        content {
          blocks
          links
          value
        }
      }
    }
  }
`

export default IndexPage
