import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1000;
  overflow: hidden;
  position: relative;
  padding: 0;
  background: var(--bg-white-color);

  .ityped-cursor {
    animation: cursor-blink 1.5s steps(2) infinite;
  }

  @keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }
`

export const Top = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 0 2rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  transition: opacity 0.8s 0.4s ease;
  will-change: opacity;
  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.brand[500]};
    background: linear-gradient(
      -45deg,
      rgba(112, 245, 112, 1) 0%,
      rgba(82, 209, 57, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    display: inline-block;
    text-indent: -9999px;
  }

  ${({ theme }) => theme.media.s} {
    font-size: 2.5rem;
  }

  ${({ theme }) => theme.media.l} {
    font-size: 3rem;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 4rem;
  transition: opacity 0.8s 0.6s ease;
  will-change: opacity;
  ${({ active }) =>
    active
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  > a + div {
    margin-left: 10px;
  }
`
