import React from 'react'
import PropTypes from 'prop-types'

import Link from '@atoms/Link/index.js'

import { Wrapper, Tweets, Preview } from './styles.js'

import Tweet1 from '@images/index/tweet1.png'
import Tweet2 from '@images/index/tweet2.png'
import Tweet3 from '@images/index/tweet3.png'
import Tweet4 from '@images/index/tweet4.png'
import Tweet5 from '@images/index/tweet5.png'
import AppPreviewImage from '@images/index/app-preview-roadmap.png'

const HeroAnimation = ({ active }) => {
  return (
    <Wrapper active={active}>
      <Tweets active={active}>
        <img src={Tweet1} alt="Tweet with user feedback" />
        <img src={Tweet2} alt="Tweet with user feature request" />
        <img src={Tweet3} alt="Tweet with user claim" />
        <img src={Tweet4} alt="Tweet with user ideas" />
        <img src={Tweet5} alt="Tweet with bug report" />
      </Tweets>
      <Preview active={active}>
        <img
          src={AppPreviewImage}
          alt="Ideolo Feedback App - preview of Roadmap"
        />
      </Preview>
    </Wrapper>
  )
}

HeroAnimation.propTypes = {
  active: PropTypes.bool,
}

HeroAnimation.defaultProps = {
  active: false,
}

export default HeroAnimation
