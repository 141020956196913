import React from 'react'
import PropTypes from 'prop-types'
import Scrollspy from 'react-scrollspy'
import SVG from 'react-inlinesvg'

import {
  Wrapper,
  Content,
  ContentImage,
  ContentText,
  Images,
  ImageMobile,
  Image,
} from './styles.js'

import Container from '@atoms/Container'
import TextBox from '@atoms/TextBox'

import { features } from '@content/features'

const FeaturesSection = ({ active }) => {
  return (
    <Wrapper>
      <Container>
        <TextBox title="Features" center>
          <h2>Build products that your clients actually need</h2>
          <p>
            With Ideolo you can gather ideas directly from your clients,
            enabling them to vote on these ideas and prioritize implementation.
            This prevents wasteful spending on unnecessary features,
            ensuring your resources are invested in creating products aligned with your clients' genuine needs and preferences.
          </p>
        </TextBox>
        <Content>
          <ContentImage>
            <Images>
              <Scrollspy
                items={[
                  'feature1',
                  'feature2',
                  'feature3',
                  'feature4',
                  'feature5',
                ]}
                currentClassName="current"
              >
                {features.map(feature => (
                  <Image key={feature.id}>
                    {feature.image ? (
                      <img src={feature.image} alt={feature.short} />
                    ) : (
                      <SVG src={feature.icon} />
                    )}
                  </Image>
                ))}
              </Scrollspy>
            </Images>
          </ContentImage>
          <ContentText>
            {features.map(
              feature =>
                feature.showOnHome && (
                  <div id={feature.id} key={feature.id}>
                    <strong>{feature.short}</strong>
                    <h3>{feature.header}</h3>
                    {feature.subheader !== '' && <h4>{feature.subheader}</h4>}
                    <p>{feature.text}</p>
                    <ImageMobile>
                      {feature.image && (
                        <img src={feature.image} alt={feature.short} />
                      )}
                    </ImageMobile>
                  </div>
                )
            )}
          </ContentText>
        </Content>
      </Container>
    </Wrapper>
  )
}

FeaturesSection.propTypes = {
  active: PropTypes.bool,
}

FeaturesSection.defaultProps = {
  active: false,
}

export default FeaturesSection
