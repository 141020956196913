import styled from 'styled-components'

export const Wrapper = styled.section`
  z-index: 1000;
  padding: 100px 0;
  background: var(--bg-color);

  h2 {
    margin: 0 0 15px;
    font-size: 32px;
    text-align: center;
  }

  p {
    font-size: 18px;
    text-align: center;
  }

  ${({ theme }) => theme.media.l} {
    padding: 100px 0 150px;
  }
`

export const Content = styled.div`
  // overflow: hidden;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
  padding: 100px 0 0;

  ${({ theme }) => theme.media.l} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
`

export const ContentImage = styled.div`
  position: relative;
  display: none;

  ${({ theme }) => theme.media.l} {
    display: block;
  }
`

export const Images = styled.div`
  position: sticky;
  top: 120px;
  height: 400px;
`

export const ImageMobile = styled.div`
  margin: 10px auto 0;
  width: 80%;

  svg {
    width: 100%;
    height: auto;
  }

  img {
    margin: 0 auto;
    width: 90%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  ${({ theme }) => theme.media.l} {
    display: none;
  }
`

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  opacity: 0;
  filter: blur(5px);
  transition: all 0.8s ease;

  > svg {
    width: 80%;
    height: auto;
  }

  > img {
    width: 100%;
    height: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &.current {
    opacity: 1;
    filter: blur(0);
  }
`

export const ContentText = styled.div`
  position: relative;

  > div {
    padding: 0 0 100px;
    text-align: center;

    strong {
      font-size: 10px;
      font-weight: 600;
      line-height: 2;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.brand[500]};
      background: linear-gradient(
        -45deg,
        rgba(112, 245, 112, 1) 0%,
        rgba(82, 209, 57, 1) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      margin: 0 0 15px;
      font-size: 32px;
      font-weight: 600;
      line-height: 1.25;
    }

    h4 {
      margin: 0 0 15px;
      font-size: 24px;
      font-weight: 600;
    }

    p {
      margin: 0 auto 15px;
      max-width: 600px;
      font-size: 18px;
      line-height: 1.5;
    }
  }

  ${({ theme }) => theme.media.l} {
    > div {
      padding: 380px 0 0;

      &:first-child {
        padding: 0;
      }

      &:last-child {
        padding-bottom: 200px;
      }
    }
  }
`
