import step1icon from '@images/steps/step1.svg'
import step2icon from '@images/steps/step2.svg'
import step3icon from '@images/steps/step3.svg'
import step4icon from '@images/steps/step4.svg'

export const steps = [
  {
    id: 'hiw1',
    icon: step1icon,
    header: 'Collect clients\' ideas',
    subheader: 'Directly from your website or a public board',
  },
  {
    id: 'hiw2',
    icon: step2icon,
    header: 'Let them vote',
    subheader: 'Your clients can vote on the ideas',
  },
  {
    id: 'hiw3',
    icon: step3icon,
    header: 'Plan and prioritize ideas',
    subheader: 'Rank feature requests and build a product roadmap',
  },
  {
    id: 'hiw4',
    icon: step4icon,
    header: 'Announce new releases',
    subheader: 'Notify users about new updates',
  },
]
