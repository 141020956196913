import React from 'react'
import SVG from 'react-inlinesvg'

import TextBox from '@atoms/TextBox'

import { Wrapper, Content, Columns } from './styles.js'

import Container from '@atoms/Container'

import { steps } from '@content/steps'

const Steps = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <TextBox title="How it works" center>
            <h2>How can Ideolo help you</h2>
          </TextBox>
        </Content>
      </Container>
      <Container>
        <Columns>
          {steps.map((step, index) => (
            <TextBox
              key={step.id}
              title={`Step ${index + 1}`}
              center
              middle={false}
            >
              <SVG src={step.icon} />
              <h3>{step.header}</h3>
              <p>{step.subheader}</p>
            </TextBox>
          ))}
        </Columns>
      </Container>
    </Wrapper>
  )
}

Steps.propTypes = {}

Steps.defaultProps = {}

export default Steps
