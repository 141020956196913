import React from 'react'
import PropTypes from 'prop-types'

import AbsoluteSubtext from '@atoms/AbsoluteSubtext'
import Button from '@atoms/Button'
import Container from '@atoms/Container'
import TypedText from '@atoms/TypedText'
import HeroAnimation from '@molecules/HeroAnimation'

import { Wrapper, Top, Content } from './styles.js'
import SignUpButton from '../../molecules/SignUpButton/index.js'

const HeroSection = ({ active }) => {
  return (
    <Wrapper>
      <Container>
        <Top active={active}>
          <h1>
            Collect clients'&nbsp;
            <TypedText
              text={['feedback', 'ideas', 'feature requests', 'claims']}
            />
            <span>feedback / ideas / feature requests / claims</span> <br />
            to create a valuable product
          </h1>
        </Top>
        <Content active={active}>
          <a
            href="https://calendly.com/ideolo/demo"
            target="_blank"
            rel="noreferrer"
          >
            <Button size="L" color="light">
              Let's talk
            </Button>
          </a>
          <SignUpButton noCreditCard text="Sign up for free" />
        </Content>
        <HeroAnimation active={active} />
      </Container>
    </Wrapper>
  )
}

HeroSection.propTypes = {
  active: PropTypes.bool,
}

HeroSection.defaultProps = {
  active: false,
}

export default HeroSection
